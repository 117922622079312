<template>
    <div>
        <v-container class="a-container">
            <div :class="mobileCentering" class="display-1 primary-text font-weight-bold mb-2 mt-3">Activity</div>
            <v-divider></v-divider>
            <v-row v-if="!isMobile" class="mt-3" :justify="mobileFilterCentering">
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="selectedStartDateFilter"
                                label="Transactions From"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="selectedStartDateFilter"
                            @input="startDateMenu = false"
                            :min="minTransactionDate"
                            :max="maxTransactionDate"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="selectedEndDateFilter"
                                label="Transactions Up To"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="selectedEndDateFilter"
                            @input="endDateMenu = false"
                            :min="minTransactionDate"
                            :max="maxTransactionDate"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="bookingTypes"
                        label="Booking Type"
                        v-model="selectedBookingTypeFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="transactionTypes"
                        label="Transaction Type"
                        v-model="selectedTransactionTypeFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="currencies"
                        label="Currency"
                        v-model="selectedCurrencyFilters"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="USD Amount From"
                        v-model.number="selectedTransactionAmountLower"
                        type="number"
                        clearable
                        prefix="$"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="USD Amount Up To"
                        v-model.number="selectedTransactionAmountUpper"
                        type="number"
                        clearable
                        prefix="$"
                    ></v-text-field>
                </v-col>
            </v-row>
            <report-summary-card
                report-title="Activity Details"
                :loading="transactionsLoading"
                show-export
                export-file-name="activity_details.csv"
                :export-data="exportData"
            >
                <template v-slot:table>
                    <div
                        @mouseleave="showToolTip = false"
                    >
                        <v-data-table
                            :headers="tableHeaders"
                            :items="filteredTransactions"
                            :loading="transactionsLoading"
                            :mobile-breakpoint="0"
                            :custom-sort="customSort"
                            fixed-header
                            :height="filteredTransactions.length > 10 ? 525 : 50 + filteredTransactions.length * 52.5"
                        >
                            <template v-slot:item="{item, headers}">
                                <tr
                                    @mouseenter="showToolTip = true"
                                >
                                    <td
                                        :class="getColumnClasses(header, item)"
                                        :key="index" v-for="(header, index) in headers"
                                        @click="getToolTipBreakdown(item)"
                                        @mouseover="getToolTipBreakdown(item)"
                                        @mousemove="updateToolTipPosition"
                                    >
                                        {{ item[header.value] }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <tx-activity-tool-tip
                            v-if="showToolTipSwitch"
                            :data="tooltipData"
                            :position-x="positionX"
                            :position-y="positionY"
                        ></tx-activity-tool-tip>
                    </div>
                </template>
            </report-summary-card>

        </v-container>
        <mobile-filter-pane>
            <template v-slot:filters>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="selectedStartDateFilter"
                                label="Transactions From"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="selectedStartDateFilter"
                            @input="startDateMenu = false"
                            :min="minTransactionDate"
                            :max="maxTransactionDate"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="selectedEndDateFilter"
                                label="Transactions Up To"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="selectedEndDateFilter"
                            @input="endDateMenu = false"
                            :min="minTransactionDate"
                            :max="maxTransactionDate"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="bookingTypes"
                        label="Booking Type"
                        v-model="selectedBookingTypeFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="transactionTypes"
                        label="Transaction Type"
                        v-model="selectedTransactionTypeFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="currencies"
                        label="Currency"
                        v-model="selectedCurrencyFilters"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="USD Amount From"
                        v-model.number="selectedTransactionAmountLower"
                        type="number"
                        clearable
                        prefix="$"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="USD Amount Up To"
                        v-model.number="selectedTransactionAmountUpper"
                        type="number"
                        clearable
                        prefix="$"
                    ></v-text-field>
                </v-col>
            </template>
        </mobile-filter-pane>
    </div>
</template>

<script>
    import ReportSummaryCard from '../components/dashboard/ReportSummaryCard';
    import ClearableChipsSelector from '../components/base/filters/ClearableChipsSelector'
    import MobileFilterPane from '../components/base/filters/MobileFilterPane'
    import {mapState, mapGetters} from 'vuex'
    import {currencyFormatterNoDecimals, currencyFormatter} from "../utils/format.utils";
    import {sortBy} from "../utils/data.utils";
    import TxActivityToolTip from '../components/reports/TxActivityToolTip'

    export default {
        name: "Activity",
        components: {
            ClearableChipsSelector, TxActivityToolTip,
            MobileFilterPane, ReportSummaryCard
        },
        data(){
            return {
                tableHeaders: [
                    {text: 'Date', value: 'date', width: "120"},
                    {text: 'Custodian', value: 'custodian', width: 120},
                    {text: 'Account', value: 'portfolio', width: "200"},
                    {text: 'Transaction Type', value: 'order_type_classification',  width: "300"},
                    {text: 'Description', value: 'comment', width: "400"},
                    {text: 'Currency', value: 'currency', width: "100"},
                    {text: 'Amount', value: 'ocvFormatted', align: 'end', width: 100},
                    {text: 'USD Amount', value: 'formattedValue', align: 'end', width: 100},
                ],
                startDateMenu: false,
                endDateMenu: false,
                showToolTip: false,
                tooltipData: [],
                positionX: 0,
                positionY: 0
            }
        },
        methods: {
            formatCurrency(value){
                return currencyFormatterNoDecimals.format(value)
            },
            getHeaderClass(header){
                return  header.align ? `text-${header.align}` : ''
            },
            getColumnClasses(header){

                let headerClass = this.getHeaderClass(header);
                return [headerClass].join(' ');

            },
            updateToolTipPosition(){
                let posY = event.clientY;
                posY -= 32; // Table header
                posY -= (24 * this.tooltipHeight); // table body height
                posY -= 15; // Buffer
                this.positionY = posY;
                this.positionX = event.clientX;
            },
            getToolTipBreakdown(record){
                this.updateToolTipPosition();
                let valuesForToolTip = [
                    {attribute: 'Quantity', value: 'quantity'},
                    {attribute: 'Price', currency: 'price_currency', value: 'price'},
                    {attribute: 'Fees', currency: 'commission_currency', value: 'commission'},
                    {attribute: 'Accrued Interest', currency: 'interest_currency', value: 'interest'},
                    {attribute: 'Withholding Tax', currency: 'tax_currency', value: 'tax'}
                ]
                ;

                this.tooltipData = [];

                valuesForToolTip.forEach(item => {
                    let itemVal = parseFloat(record[item.value]);

                    if (itemVal !== 0){
                        let dataRecord = {
                            attribute: item.attribute,
                            currency: item.currency ? record[item.currency] : '',
                        };
                        if(item.currency){
                            let parts = itemVal.toFixed(2).split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            dataRecord.value = parts.join(".");

                        } else if (item.attribute === 'Quantity'){
                            let parts = itemVal.toFixed(2).split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            dataRecord.value = parts.join(".");
                        } else {
                            dataRecord.value = currencyFormatter.format(itemVal)
                        }

                        this.tooltipData.push(dataRecord)
                    }
                });
            },
            customSort(items, index, isDescending) {
                if (index.length === 0) {
                    return items
                }
                const isDesc = !isDescending[0];  // reversed so that ascending sort is really descending
                const sortField = index[0];
                const keys = Object.keys(this.filteredTransactions[0] || {});
                const hasRawField = keys.includes(sortField + '_raw');
                let sortFunc;
                if (hasRawField) {
                    const rawField = sortField + '_raw';
                    if (!isDesc) {
                        sortFunc = sortBy(rawField);
                    } else {
                        sortFunc = sortBy({name: rawField, reverse: true})
                    }
                } else {
                    if (!isDesc) {
                        sortFunc = sortBy(sortField);
                  } else {
                        sortFunc = sortBy({name: sortField, reverse: true})
                  }
                }

                return items.sort(sortFunc)
            }
        },
        computed: {
            ...mapGetters([
                'accountIds', 'groups', 'managers', 'transactionsLoading',
                'minTransaction', 'maxTransaction', 'banks', 'transactionTypes',
                'bookingTypes', 'minTransactionDate', 'maxTransactionDate',
                'currencies'
            ]),
            ...mapState([
                'groupFilter', 'managerFilter', 'accountFilter',
                'bankFilter', 'transactionsStartDateFilter',
                'transactionsEndDateFilter', 'transactionTypeFilter',
                'bookingTypeFilter', 'transactionsAmountLowerFilter',
                'transactionsAmountUpperFilter', 'userTransactions',
                'currencyFilter'
            ]),
            selectedGroupFilter: {
                set(groups){
                    this.$store.commit('setGroupFilter', groups)
                },
                get(){
                    return this.groupFilter
                }
            },
            selectedManagerFilter: {
                set(managers){
                    this.$store.commit('setManagerFilter', managers)
                },
                get(){
                    return this.managerFilter
                }
            },
            selectedAccountFilter: {
                set(accounts){
                    this.$store.commit('setAccountFilter', accounts)
                },
                get(){
                    return this.accountFilter
                }
            },
            selectedBankFilter: {
                set(banks){
                    this.$store.commit('setBankFilter', banks)
                },
                get(){
                    return this.bankFilter
                }
            },
            selectedStartDateFilter: {
                set(date){
                    this.$store.commit('setTransactionsStartDateFilter', date)
                },
                get(){
                    return this.transactionsStartDateFilter
                }
            },
            selectedEndDateFilter: {
                set(date){
                    this.$store.commit('setTransactionsEndDateFilter', date)
                },
                get(){
                    return this.transactionsEndDateFilter
                }
            },
            selectedTransactionTypeFilter: {
                set(types){
                    this.$store.commit('setTransactionTypeFilter', types)
                },
                get(){
                    return this.transactionTypeFilter
                }
            },
            selectedBookingTypeFilter: {
                set(types){
                    this.$store.commit('setBookingTypeFilter', types)
                },
                get(){
                    return this.bookingTypeFilter
                }
            },
            selectedTransactionAmountLower: {
                set(value){
                    this.$store.commit('setTransactionAmountLowerFilter', value)
                },
                get(){
                    return this.transactionsAmountLowerFilter
                }
            },
            selectedTransactionAmountUpper: {
                set(value){
                    this.$store.commit('setTransactionAmountUpperFilter', value)
                },
                get(){
                    return this.transactionsAmountUpperFilter
                }
            },
            selectedCurrencyFilters: {
                set(values){
                    this.$store.commit('setCurrencyFilter', values)
                },
                get(){
                    return this.currencyFilter
                }
            },
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering() {
                return this.isMobile ? 'text-center' : ''
            },
            mobileFilterCentering() {
                return this.isMobile ? 'center' : null
            },

            filteredTransactions(){
                let filteredTx = this.userTransactions.filter(tx => {

                    if (this.selectedTransactionAmountLower !== null) {
                        return tx.value >= this.selectedTransactionAmountLower
                    }
                    return true
                }).filter(tx => {

                    if (this.selectedTransactionAmountUpper !== null) {
                        return tx.value <= this.selectedTransactionAmountUpper
                    }
                    return true

                }).filter(tx => {
                    return this.selectedBankFilter.includes(tx.custodian)
                }).filter(tx => {
                    return this.selectedTransactionTypeFilter.includes(tx.order_type_classification)
                }).filter(tx => {
                    return this.selectedBookingTypeFilter.includes(tx.booking_type)
                }).filter(tx => {
                    return new Date(this.selectedStartDateFilter) <= new Date(tx.date)
                }).filter(tx =>{
                    return new Date(this.selectedEndDateFilter) >= new Date(tx.date)
                }).filter(tx => {
                    return this.selectedGroupFilter.includes(tx.group)
                }).filter(tx => {
                    return this.selectedManagerFilter.includes(tx.manager)
                }).filter(tx => {
                    return this.selectedAccountFilter.includes(tx.portfolio)
                }).filter(tx => {
                    return this.selectedCurrencyFilters.includes(tx.currency)
                });

                filteredTx.forEach(tx => {
                    tx.formattedValue = currencyFormatter.format(tx.value);
                    tx.formattedValue_raw = tx.value;
                    let parts = tx.original_currency_value.toFixed(2).split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    tx.ocvFormatted = parts.join(".");
                    tx.ocvFormatted_raw = tx.original_currency_value;
                    tx.dateVal = new Date(tx.date)
                });

                let sortFunc = sortBy({name: 'dateVal', reverse:true});

                return filteredTx.sort(sortFunc)
            },
            tooltipHeight(){
                return this.tooltipData.length;
            },
            showToolTipSwitch(){
                if (this.tooltipData.length === 0){
                    return false
                }

                return this.showToolTip
            },
            exportData() {

                let exportData = []
                this.filteredTransactions.forEach(o => {
                    const newObject = {
                        'Date': o.date,
                        'Custodian': o.custodian,
                        'Account': o.portfolio,
                        'Transaction Type': o.order_type_classification,
                        'Description': o.comment,
                        'Currency': o.currency,
                        'Amount': o.original_currency_value,
                        'USD Amount': o.value,
                        'Quantity': o.quantity,
                        'Price Currency': o.price_currency,
                        'Price': o.price,
                        'Fees Currency': o.commission_currency,
                        'Fees': o.commission,
                        'Withholding Tax Currency': o.tax_currency,
                        'Withholding Tax': o.tax
                    }
                    exportData.push(newObject)
                })

                return exportData
            }
        },
    }
</script>

<style scoped>
>>>.v-slider__thumb-label{
    border-radius: 500px;
    transform: translateY(-20%) translateY(-12px) translateX(-50%) !important;
    width: 70px !important;
}

>>>.v-slider__thumb{
    cursor: pointer;
}

>>>.v-slider--horizontal .v-slider__thumb-label > *{
    -webkit-transform: none;
    transform: none;
}

>>> .v-data-table-header th.desc .v-data-table-header__icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

>>> .v-data-table-header th.asc .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

>>> .v-data-table-header th:hover:not(.desc):not(.asc) .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>