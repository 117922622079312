<template>
    <div class="tooltip">
        <v-menu
            :value="true"
            @change="$emit('input', show)"
            :close-on-content-click="false"
            :close-on-click="false"
            absolute
            :position-x="positionX + 15"
            :position-y="positionY"
        >
            <v-card>
                <v-data-table
                    :items="data"
                    :headers="tableHeaders"
                    disable-sort
                    hide-default-footer
                    disable-pagination
                    dense
                >
                </v-data-table>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "TxActivityToolTip",
        props: ['data', 'value', 'positionX', 'positionY'],
        data(){
            return {
                tableHeaders: [
                    {text: 'Attribute', value: 'attribute'},
                    {text: 'Currency', value: 'currency'},
                    {text: 'Value', value: 'value', align: 'end'},
                ]
            }
        }
    }
</script>

<style scoped>
.tooltip{
    pointer-events: none;
}
</style>